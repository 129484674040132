import React from 'react';
import { StoreContainer } from './store';

function SelectSalesChannel() {
  const storeContainer = StoreContainer.useContainer();

  const change = (selected) => {
    storeContainer.setSalesChannel(selected.target.value);
    window.location.reload();
  };

  return (
    <select className="form-control" value={storeContainer.salesChannel} onChange={change}>
      <option>販売チャネルを選択</option>
      <option value="takeout">テイクアウト</option>
      <option value="eatin">イートイン</option>
    </select>
  );
}

export default SelectSalesChannel;
