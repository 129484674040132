import React from 'react';
import { StoreContainer } from './store';

function Order(props: {
  number: number | string;
  nickname: string;
  shelfLabel: string | null | undefined;
  small: boolean;
}) {
  const storeContainer = StoreContainer.useContainer();

  return (
    <div
      className="shimmer"
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'baseline',
      }}
    >
      <div style={{ fontFamily: '"Oswald", "Helvetica", "Arial", sans-serif' }}>
        {props.shelfLabel && (
          <span style={{ fontSize: props.small ? '20px' : '24px', marginRight: '0.1em' }}>{props.shelfLabel}-</span>
        )}
        <span style={{ fontSize: props.small ? '12px' : '16px', marginRight: '0.5em' }}>
          <span>No.</span>
          <strong style={{ fontSize: props.small ? '24px' : '32px', fontWeight: '500' }}>{props.number}</strong>
        </span>
      </div>

      {storeContainer.enabledNickname && (
        <div>
          <span
            style={{
              fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
              fontSize: props.small ? '12px' : '16px',
              fontWeight: 'bold',
            }}
          >
            {props.nickname}
          </span>
        </div>
      )}
    </div>
  );
}

export default Order;
