import { useEffect, useState } from 'react';
import firebase from '../firebase';
import { StoreContainer } from '../store';
import dayjs from '../dayjs';

export interface IOrder {
  id: string;
  data: firebase.firestore.DocumentData;
}

const useOrders = () => {
  const [orders, setOrders] = useState<Array<IOrder>>();

  const storeContainer = StoreContainer.useContainer();

  const orderSortDate = (orderData: any) => {
    let date: Date;
    if (orderData.cooking_slots && orderData.cooking_slots.length > 0) {
      date = orderData.cooking_slots[orderData.cooking_slots.length - 1].time.toDate();
    } else {
      // 外部デリバリーではcooking_slotsが存在しないため
      date = orderData.pickup_until_at.toDate();
    }
    return date;
  };

  useEffect(() => {
    if (storeContainer.shopIds?.length > 0) {
      let query = firebase
        .firestore()
        .collection('orders')
        .where('pickup_at', '>=', dayjs().tz('Asia/Tokyo').startOf('day').toDate());

      query = query.where('kitchen_shop_id', 'in', storeContainer.shopIds);
      query = query.where('pickup_at', '<', dayjs().tz('Asia/Tokyo').endOf('day').toDate());
      query = query.where('done_by_staff', '==', false);

      const unregisterOrdersObserver = query.onSnapshot((snap) => {
        const records: Array<IOrder> = snap.docs
          .filter((doc) => {
            const data = doc.data();
            return data.sales_channel === storeContainer.salesChannel;
          })
          .map((doc) => {
            const data = doc.data();
            return {
              id: doc.id,
              data,
            };
          });

        // pickup_at / estimated_at / ordered_at  の順にソート
        records.sort((a, b) => (orderSortDate(a.data) > orderSortDate(b.data) ? 1 : -1));

        setOrders(records);
      });

      return () => {
        unregisterOrdersObserver();
      };
    }
    return () => {};
  }, [storeContainer.shopIds]);

  return orders;
};

export default useOrders;
