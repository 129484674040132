import React, { useEffect, useState } from 'react';
import firebase from './firebase';
import { StoreContainer } from './store';

function SelectShop() {
  const storeContainer = StoreContainer.useContainer();

  const change = (selected) => {
    const { options } = selected.target;

    const shopIds: string[] = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        shopIds.push(options[i].value);
      }
    }

    storeContainer.setShopIds(shopIds);
  };

  return (
    <select className="form-control" multiple onChange={change}>
      <option>店舗を選択</option>
      {Object.keys(storeContainer.shopData ?? []).map((shopId: string) => (
        <option key={shopId} value={shopId} selected={storeContainer.shopIds?.includes(shopId)}>
          {storeContainer.shopData[shopId].short_name}
        </option>
      ))}
    </select>
  );
}

export default SelectShop;
