import { useState } from 'react';
import { createContainer } from 'unstated-next';

export const useStore = () => {
  const [shopIds, setShopIds] = useLocalStorage('shop_ids', []);
  const [shopData, setShopData] = useLocalStorage('shop_data', {});
  const [salesChannel, setSalesChannel] = useLocalStorage('sales_channel', 'NA');
  const [enabledNickname, setEnabledNickname] = useLocalStorage('enabled_nickname', true);
  const [enabledCookingOrder, setEnabledCookingOrder] = useLocalStorage('enabled_cooking_order', false);

  return {
    shopIds,
    setShopIds,
    shopData,
    setShopData,
    salesChannel,
    setSalesChannel,
    enabledNickname,
    setEnabledNickname,
    enabledCookingOrder,
    setEnabledCookingOrder,
  };
};

function useLocalStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

export const StoreContainer = createContainer(useStore);
