import React, { useEffect, useState } from 'react';
import firebase from './firebase';
import Board from './Board';
import Login from './Login';
import './style.css';
import withTMCAuth from './withTMCAuth';

const App = (props: { user; signOut }) => {
  const { user, signOut } = props;
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    if (user) {
      if (user.providerData[0].providerId === 'google.com' && user.email.match(/@tokyomixcurry.com$/)) {
        setLoggedIn(true);
      } else {
        (async () => {
          try {
            const adminSnapshot = await firebase.firestore().collection('admins').doc(user.uid).get();

            if (adminSnapshot.exists) {
              // @tokyomixcurry.com以外でも、adminsに登録されているユーザーならログイン可
              setLoggedIn(true);
            } else {
              setLoggedIn(false);
              signOut();
            }
          } catch (e) {
            setLoggedIn(false);
            signOut();
            alert(e);
          }
        })();
      }
    } else {
      setLoggedIn(false);
      signOut();
    }
  }, [user, signOut]);

  return user && loggedIn ? <Board /> : <Login />;
};

export default withTMCAuth(App);
