import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import SelectShop from './SelectShop';
import useOrders, { IOrder } from './hooks/useOrders';
import Order from './Order';
import SelectSalesChannel from './SelectSalesChannel';
import { StoreContainer } from './store';
import CheckEnabledNickname from './CheckEnabledNickname';
import CookingOrder from './CookingOrder';
import CheckEnabledCookingOrder from './CheckEnabledCookingOrder';
import withTMCAuth from './withTMCAuth';
import firebase from './firebase';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.85)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    border: '0px',
    background: 'transparent',
    color: '#000',
  },
};

Modal.setAppElement('#root');

const Board = (props: { signOut; error }) => {
  const orders = useOrders();

  const coockedOrders = orders?.filter((order: IOrder) => order.data.status === 'cooked' && order.data.notified_at);
  const coockingOrders = orders?.filter((order: IOrder) => order.data.status === 'cooking');
  const hasShelflessOrder = (coockedOrders ?? []).some((order: IOrder) => !order.data.shelf_label);

  const storeContainer = StoreContainer.useContainer();

  useEffect(() => {
    const unregisterShopsObserver = firebase
      .firestore()
      .collection('shops')
      .where('status', '==', 'active')
      .orderBy('order')
      .onSnapshot((snap) => {
        const records = {};
        snap.forEach((docSnapshot) => {
          records[docSnapshot.id] = docSnapshot.data();
        });
        storeContainer.setShopData(records);
      });

    return () => {
      unregisterShopsObserver();
    };
  }, []);

  const shelfEnabled =
    storeContainer.shopIds.some(
      (shopId) => shopId in storeContainer.shopData && storeContainer.shopData[shopId].shelf,
    ) && storeContainer.salesChannel !== 'eatin';

  const showShelflessInSeparateFlame = hasShelflessOrder && shelfEnabled;

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const salesChannelLabelJa = () => {
    switch (storeContainer.salesChannel) {
      case 'takeout':
        return 'テイクアウト';
      case 'eatin':
        return 'イートイン';
      default:
        return '-';
    }
  };

  const salesChannelLabelEn = () => {
    switch (storeContainer.salesChannel) {
      case 'takeout':
        return 'Takeout';
      case 'eatin':
        return 'Eat in';
      default:
        return '-';
    }
  };

  return (
    <>
      <Modal isOpen={modalIsOpen} style={customStyles} contentLabel="ボードアプリの設定">
        <div className="card">
          <div className="card-header">ボードアプリの設定</div>
          <div className="card-body">
            <h5 className="card-title">表示する店舗</h5>
            <p className="card-text">
              <SelectShop />
            </p>
            <h5 className="card-title">表示する販売チャネル</h5>
            <p className="card-text">
              <SelectSalesChannel />
            </p>
            <p className="card-text">
              <CheckEnabledCookingOrder />
            </p>
            <p className="card-text">
              <CheckEnabledNickname />
            </p>

            <button type="button" className="btn btn-link " onClick={closeModal}>
              閉じる
            </button>
            <button type="button" className="btn btn-link text-danger" onClick={props.signOut}>
              サインアウト
            </button>
          </div>
        </div>
      </Modal>
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          flexFlow: 'row nowrap',
          position: 'relative',
        }}
      >
        {storeContainer.enabledCookingOrder && (
          <div style={{ width: '30%', height: '100%', display: 'flex', flexFlow: 'column nowrap' }}>
            <div style={{ height: '20%' }} />

            <div
              style={{
                height: '75%',
                background: 'rgba(0, 0, 0, 0.3)',
                margin: '0 24px',
                padding: '1em',
                borderRadius: '10px',
                overflow: 'hidden',
              }}
            >
              <div style={{ marginBottom: '1em', lineHeight: '1.2', textAlign: 'center' }}>
                <span
                  style={{
                    fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
                    fontWeight: 'bold',
                    fontSize: '24px',
                  }}
                >
                  Cooking...
                </span>
                <br />
                <span
                  style={{
                    fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
                    fontWeight: 'bold',
                    fontSize: '14px',
                  }}
                >
                  調理中
                </span>
              </div>
              {(coockingOrders ?? []).map((order) => (
                <CookingOrder number={order.data.number} nickname={order.data.nickname} />
              ))}
            </div>
          </div>
        )}
        <div
          style={{
            width: storeContainer.enabledCookingOrder ? '70%' : '100%',
            height: '100%',
            display: 'flex',
            flexFlow: 'column nowrap',
          }}
        >
          <div style={{ height: '20%', position: 'relative' }}>
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <div style={{ marginTop: '1em', lineHeight: '1.2', textAlign: 'center' }}>
                <span
                  style={{
                    fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
                    fontWeight: 'bold',
                    fontSize: '32px',
                  }}
                >
                  Cooked!
                </span>
                <br />
                <span
                  style={{
                    fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
                    fontWeight: 'bold',
                    fontSize: '18px',
                  }}
                >
                  調理完了
                </span>
              </div>
            </div>
          </div>

          <div
            style={{
              height: '75%',
              display: 'flex',
              flexFlow: 'column wrap',
              justifyContent: 'flex-start',
              alignContent: 'center',
              alignItems: 'stretch',
            }}
          >
            <div
              style={{
                height: showShelflessInSeparateFlame ? '64%' : '100%',
                display: 'flex',
                flexFlow: 'column wrap',
                justifyContent: 'flex-start',
                alignContent: 'center',
                alignItems: 'stretch',
              }}
            >
              {props.error === undefined ? (
                <div className="alert alert-danger" role="alert">
                  {props.error.message}
                </div>
              ) : (
                <>
                  {coockedOrders === undefined ? (
                    <div className="spinner-border">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    coockedOrders
                      .filter((order) => !!order.data.shelf_label || !shelfEnabled)
                      .map((order) => (
                        <div key={order.id} style={{ margin: '0.1em 1em' }}>
                          <Order
                            number={order.data.number}
                            nickname={order.data.nickname}
                            shelfLabel={order.data.shelf_label}
                            small={false}
                          />
                        </div>
                      ))
                  )}
                </>
              )}
            </div>
            {showShelflessInSeparateFlame && (
              <div
                style={{
                  width: '100%',
                  height: '36%',
                  paddingRight: '24px',
                }}
              >
                <fieldset
                  style={{
                    border: '1px solid #fff',
                    borderRadius: '10px',
                    padding: '0 0.5em 0.5em 0.5em',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <legend style={{ width: 'auto', margin: '0 auto', padding: '0 0.5em' }}>
                    <div style={{ display: 'inline-block', marginTop: '1em', lineHeight: '0.8', textAlign: 'center' }}>
                      <span
                        style={{
                          fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
                          fontWeight: 'bold',
                          fontSize: '20px',
                        }}
                      >
                        Please contact our staff!
                      </span>
                      <br />
                      <span
                        style={{
                          fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
                          fontWeight: 'bold',
                          fontSize: '14px',
                        }}
                      >
                        スタッフにお声がけください
                      </span>
                    </div>
                  </legend>
                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      flexFlow: 'column wrap',
                      justifyContent: 'flex-start',
                      alignContent: 'center',
                      alignItems: 'stretch',
                    }}
                  >
                    {coockedOrders === undefined ? (
                      <div className="spinner-border">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      coockedOrders
                        .filter((order) => !order.data.shelf_label)
                        .map((order) => (
                          <div key={order.id} style={{ margin: '0.1em 0.2em' }}>
                            <Order
                              number={order.data.number}
                              nickname={order.data.nickname}
                              shelfLabel={order.data.shelf_label}
                              small
                            />
                          </div>
                        ))
                    )}
                  </div>
                </fieldset>
              </div>
            )}
          </div>
        </div>

        <div
          style={{
            position: 'absolute',
            top: '24px',
            left: '24px',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {/*  eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
            <img
              style={{ marginRight: '16px' }}
              className="rounded"
              src="/images/logo.png"
              width="32"
              height="32"
              alt="ロゴ"
              onClick={openModal}
            />
            <div style={{ lineHeight: '1.2' }}>
              <span
                style={{
                  fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
                  fontWeight: 'bold',
                  fontSize: '24px',
                }}
              >
                {salesChannelLabelEn()}
              </span>
              <br />
              <span
                style={{
                  fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
                  fontWeight: 'bold',
                  fontSize: '12px',
                }}
              >
                {salesChannelLabelJa()}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTMCAuth(Board);
