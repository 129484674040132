import React from 'react';
import { render } from 'react-dom';
import App from './App';
import { StoreContainer } from './store';

// adhoc: Squareのcallbackでadmin=1が削除されてしまうので追加
if (window.location.href.match(/com.squareup.pos/)) {
  const pageUrl = `${window.location.href}&admin=1`;
  window.history.pushState('', '', pageUrl);
}

// 登録されてしまったservice-workerを削除
if (navigator.serviceWorker) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    for (const registration of registrations) {
      registration.unregister();
    }
  });
}

const Index = () => (
  <StoreContainer.Provider>
    <App />
  </StoreContainer.Provider>
);

render(<Index />, document.getElementById('root'));
