import React from 'react';
import { StoreContainer } from './store';

function CheckEnabledNickname() {
  const storeContainer = StoreContainer.useContainer();

  const change = (selected) => {
    storeContainer.setEnabledNickname(selected.target.checked);
    window.location.reload();
  };

  return (
    <div className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        id="enabled_nickname"
        checked={storeContainer.enabledNickname}
        onChange={change}
      />
      <label className="form-check-label" htmlFor="enabled_nickname">
        ニックネームを表示する
      </label>
    </div>
  );
}

export default CheckEnabledNickname;
