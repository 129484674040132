import React, { useState } from 'react';
import './style.css';
import withTMCAuth from './withTMCAuth';

// const email = "test@test.com";
// const password = "password";

const Login = (props: { signInWithGoogle; error }) => {
  return (
    <div id="form-signin-container">
      <form className="form-signin" onSubmit={(e) => e.preventDefault()}>
        <div className="text-center mb-4">
          <img className="mb-4 rounded" src="/images/logo.png" alt="logo" width="64" height="64" />
          <h1 className="h3 mb-3 font-weight-normal">TMC Board App</h1>
        </div>

        {props.error ? (
          <div className="alert alert-danger" role="alert">
            {props.error.message}
          </div>
        ) : null}

        <div>
          <button type="button" className="btn btn-lg btn-secondary btn-block" onClick={props.signInWithGoogle}>
            Googleアカウントでログイン
          </button>
        </div>
      </form>
    </div>
  );
};

export default withTMCAuth(Login);
