import React from 'react';
import { StoreContainer } from './store';

function CookingOrder(props: { number: number | string; nickname: string }) {
  const storeContainer = StoreContainer.useContainer();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'baseline',
        }}
      >
        <div style={{ fontFamily: '"Oswald", "Helvetica", "Arial", sans-serif' }}>
          <span style={{ fontSize: '12px', marginRight: '0.5em' }}>
            <span>No.</span>
            <strong style={{ fontSize: '24px', fontWeight: '500' }}>{props.number}</strong>
          </span>
        </div>

        {storeContainer.enabledNickname && (
          <div>
            <span
              style={{
                fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
                fontSize: '12px',
                fontWeight: 'bold',
              }}
            >
              {props.nickname}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default CookingOrder;
